import React, { useRef } from "react";
import logo from "../media/images/ft-logo-1.png";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("landing-nav-open");
  };

  return (
    <div>
      <div className="landing-nav" >
        <div className="landing-nav-main">
          <div className="landing-nav-logo">
            <img src={logo} />
          </div>

          <div className="landing-nav-menu" ref={navRef}>
            <div
              className="landing-nav-btn landing-nav-close-btn"
              onClick={showNavbar}
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </div>

                        <div className="navbar-drop">
                            <span>Services <i className="fa-solid fa-caret-down"></i></span>
                            <div className="navbar-drop-option">
                              <a onClick={showNavbar} href="#grooming">Grooming</a>
                              <a onClick={showNavbar} href="#haircut">Haircut</a>
                            </div>
                          </div>

            <a onClick={showNavbar} href="#how-we-work">How we work</a>
            <a onClick={showNavbar} href="#why-us">Why us</a>
            <a onClick={showNavbar} href="#faq">Faqs</a>
            <a onClick={showNavbar} href="#testimonials">Testimonials</a>
          </div>

          <div
            className="landing-nav-btn landing-nav-menu-btn"
            >
                    <Link title="Pet Grooming At Home" target="_blank" to="tel:+919658189189"> <i class="fa-solid fa-phone"></i>+919658-189-189</Link>
              
            
            <i onClick={showNavbar} className="fa-solid fa-bars"></i>
          </div>
        </div>
      </div>

      {/* <div className="navbar-main"></div>
      <div className="navbar">
        <div className="nav-logo">
          <Link title="Waxing Near Me" to="/">
            <img src={logo} alt="logo" title="Men's salon at home" />
          </Link>
        </div>

        <div className="nav-menu" ref={navRef}>
          <a href="#grooming">Grooming</a>
          <a href="#haircut">Haircut</a>
          <a href="#how-we-work">How we work</a>
          <a href="#why-us">Why us</a>
          <a href="#testimonials">Testimonials</a>
        </div>
        <button className="nav-btn menu-btn" onClick={showNavbar}>
          <i className="fa-solid fa-bars"></i>
        </button>
      </div> */}
    </div>
  );
};

export default LandingNavbar;
