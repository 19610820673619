import React from 'react'

// exploreGrooming ----------------------------------------------------------------------------------
import serv from "../media/images/book-your-service-icon.png";
import loc from "../media/images/we-come-at-your-home-icon.png";
import groome from "../media/images/TAKE-CARE-ICON.png";
import clean from "../media/images/post-service-cleanup-icon.png";
import pay from "../media/images/PAY-ICON.png";


const LandingHowWeWork = ({location}) => {

  
  
  return (
    <div>

         <div className="how-ft" id='how-we-work'>
                <div>
                  <div className="how-ft-heading">
                    How <span>Fluffy Touch</span> Works in <span className='location-page-location'>{location}</span> ?
                  </div>
                  <div className="how-ft-text"></div>
        
                  <div className="how-ft-content">
                    <div className="how-ft-col">
                      <div className="how-ft-col-icon">
                        <img src={serv} alt="" />
                      </div>
                      <div className="how-ft-col-heading how-ft-col-heading-1">
                        Book Service
                      </div>
                      <div className="how-ft-col-text">
                        Select the services for your pet with a preferred schedule.
                      </div>
                    </div>
        
                    <div className="how-ft-col">
                      <div className="how-ft-col-icon">
                        <img src={loc} alt="" />
                      </div>
                      <div className="how-ft-col-heading how-ft-col-heading-2">
                        We Come at You
                      </div>
                      <div className="how-ft-col-text">
                        Our groomers reach at your location for your pet service.
                      </div>
                    </div>
        
                    <div className="how-ft-col">
                      <div className="how-ft-col-icon">
                        <img src={groome} alt="" />
                      </div>
                      <div className="how-ft-col-heading how-ft-col-heading-3">
                        Get It Groomed
                      </div>
                      <div className="how-ft-col-text">
                        Get your pet groomed by our groomer at your location.
                      </div>
                    </div>
        
                    
                  <div className="how-ft-col">
                    <div className="how-ft-col-icon">
                      <img src={clean} alt="" />
                    </div>
                    <div className="how-ft-col-heading how-ft-col-heading-5">
                    Post Service Cleanup
                    </div>
                    <div className="how-ft-col-text">
                      {/* Get your pet groomed by our groomer at your location. */}
                      After grooming, our team cleans and sanitizes grooming area
                    </div>
                  </div>
        
        
                    <div className="how-ft-col">
                      <div className="how-ft-col-icon">
                        <img src={pay} alt="" />
                      </div>
                      <div className="how-ft-col-heading how-ft-col-heading-4">
                        Relax & Payment
                      </div>
                      <div className="how-ft-col-text">
                        Just sit back and let our groomers do their work & than pay.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='landing-page-book-button'>
  <a href='#book-now'  >Book Now</a>
              <span id="why-us"></span>
</div>
                
              </div>

        
    </div>
  )
}

export default LandingHowWeWork