import React, { useState } from "react";
import data from "./FaqData.json"

const FaqsComponent = () => {
    const [view, setView] = useState(null)
    
  return (

    
    
    <div >
      <div className="faq-component">
        <div className="faq-component-main">
          <div className="faq-component-heading">Frequently Asked <span>Questions?</span></div>

          <div className="faq-component-content">

            {

                data&&data.map( (e, i) => (

            <div className="faq-component-content-col">

              <div className={`faq-component-question ${view === i ?"faq-component-question-active" :""}`}>
                {e.question}
                {
                    view === i?
                    <i onClick={()=>setView(null)} class="fa-solid fa-minus"></i>
                    :
                    <i onClick={()=>setView(i)} class="fa-solid fa-plus"></i>
                }
              </div>
                {

view === i?
              <div className="faq-component-answer">
                <div>
                {e.text&&e.text}
                </div>

                {e.points&&e.points.map(e=>(
                    <li>
                        {e}
                    </li>
                ))

                }
                
              </div>
:""

                }


            </div>
                ) )
                
            }
            

          </div>

          {/* <div className="faq-component-button"></div> */}



          <div className='landing-page-book-button'>
            <a href='#book-now' >Book Now</a>
            <span id="testimonials"></span>
          </div>


        </div>
      </div>
    </div>
  );
};

export default FaqsComponent;
