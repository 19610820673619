import React from 'react'

const LandingNavTop = () => {
  return (
    <div>

        <div className="landing-nav-top" >

            <div>Get 20% Off On All Services</div>
            
            <span>(Limited Period Offer*)</span>
            
        </div>
        
    </div>
  )
}

export default LandingNavTop