import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import LoadingComponent from "./LoadingComponent";

const LandingHeader = ({location}) => {

  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // form animation :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);  // Text is visible, trigger animation
      } else {
        setIsVisible(false); // Text is not visible, reset animation
      }
    }, { threshold: 0.5 });

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);


// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({

    formType: `${location} Landing Page Booking`,
    
    pet: "",
    name: "",
    phone: "",
    service: "",
    addOns: "No Add-Ons",
    breed: "",
    address: "",
    
  })
  
  
 

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:8001/booking-form", {
      const response = await fetch("https://api.shop.fluffytouch.in/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate("/thank-you");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
      
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
      <div className="landing-header" >
    <div>
        <div className="landing-header-main">
          <div className="landing-header-content">
    <div>
          <div className="landing-header-heading">

          <TypeAnimation
                    sequence={[
                      "Pet Grooming At Home",
                      3500,
                      "Pet Designer Hair Cut",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
            
          </div>
          <div className="landing-header-heading">in <span><span>{location}</span></span></div>

          </div>
          </div>
          <div className="landing-header-form">

            <form ref={formRef} onSubmit={handleSubmit} className={`${isVisible ? 'landing-header-form-animate' : ''}`}>
          <div className="landing-header-form-heading">Our Experts will call you!</div>

          <div className="landing-header-form-option">
            <div>Pet* :</div>
              
            <div>
              <input
                required
                type="radio"
                name="pet"
                onChange={handleChange}
                value="Dog"
              />
              <span>DOG</span>
              </div>
              

              <div>
              <input
                required
                type="radio"
                name="pet"
                onChange={handleChange}
                value="Cat"
              />
              <span>Cat</span>
              
            </div>
            
          </div>

          
                <input id="form-name" required onChange={handleChange} name="name" type="text" placeholder="Name *" />
                <input id="form-number" required onChange={handleChange} name="phone" type="number" placeholder="Phone number *" />


                <select
                      required
                      name="service"
                      onChange={handleChange}
                      id=""
                    >
                      <option disabled selected value="">
                        --- select service ---
                      </option>

                      
                      {/* <option value="BARK 'N BATH">BARK 'N BATH &nbsp; - &nbsp; Rs. 599/-</option>
                      <option value="PAMPER PAWS">PAMPER PAWS &nbsp; - &nbsp; Rs. 799/-</option> */}
                      <option value="FUR FRESH SPA">FUR FRESH SPA &nbsp; - &nbsp; Rs. 999/-</option>
                      <option value="BASIC GROOMING">BASIC GROOMING &nbsp; - &nbsp; Rs. 1299/-</option>
                      <option value="FULL BODY GROOMING">FULL BODY GROOMING &nbsp; - &nbsp; Rs. 1799/-</option>
                      <option value="DELUXE BODY GROOMING">DELUXE BODY GROOMING &nbsp; - &nbsp; Rs. 2399/-</option>                 
                      
                    </select>

{
  data.service?
                    <select required name="addOns" value={data.addOns} onChange={handleChange}>
                      <option value="No Add-Ons">No Add-Ons</option>
                      <option value="Tick Removal by Hand - Rs 249">Tick Removal by Hand &nbsp; - &nbsp; Rs 249</option>
                      <option value="30 Min DeStressing Massage - Rs 349">30 Min DeStressing Massage &nbsp; - &nbsp; Rs 349</option>
                      <option value="DeMatting - Rs 249">DeMatting &nbsp; - &nbsp; Rs 249</option>
                      <option value="Ear & Eyes Cleaning - Rs 199">Ear & Eyes Cleaning &nbsp; - &nbsp; Rs 199</option>
                      <option value="Nail's Clipping - Rs 249">Nail's Clipping &nbsp; - &nbsp; Rs 249</option>
                    </select>
:""}                
                
                <input id="form-breed" required onChange={handleChange} name="breed" type="text" placeholder="breed*" />
               


                <select required onChange={handleChange} name="address">

                  <option value="" selected disabled>---Select Location---</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Noida">Noida</option>
                  <option value="Greater Noida">Greater Noida</option>
                  {/* <option value="Gurugram">Gurugram</option> */}
                  <option value="Ghaziabad">Ghaziabad</option>
                  <option value="Faridabad">Faridabad</option>
                  {/* <option value="Other Location">Other Location</option> */}
                  
                </select>
                
                {/* <button>Request Call Back</button> */}
                <button>Book Now</button>
            </form>
            
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default LandingHeader;
