import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
// import './css/components/Other.css';
import './css/components/Navbar.css';
import './css/components/HeaderSlider.css';
import './css/components/WelcomeToFt.css';
import './css/components/OurMission.css';
import './css/components/GroomingPackageComponent.css';
import './css/components/WhyFt.css';
import './css/components/ReasonBehindLaunching.css';
import './css/components/Testimonial.css';
import './css/components/Footer.css';
import './css/components/NumberIncrement.css';
import './css/components/ExploreGroomingService.css';
import './css/components/Breadcrumb.css';
import './css/components/PetBoardingComponent.css';
import './css/components/HowFt.css';
import './css/components/Animation.css';
import './css/components/Loading.css';
import './css/components/PopupOffer.css';
import './css/components/PetBoardingFeatures.css';
import './css/components/PetBoardingFacility.css';
import './css/components/ContactComponent.css';
import './css/components/AboutUsComponent.css';
import './css/components/BookingForm.css';
import './css/components/ConfirmBooking.css';
import './css/components/ErrorPage.css';
import './css/components/BlogPage.css';
import './css/components/SingleBlog.css';
import './css/components/BoardingPageComponent.css';
import './css/components/Gallery.css';
import './css/components/KeyWords.css';
import './css/components/LocationPageComponent.css';
import './css/components/BoardingPagePrice.css';
import './css/components/GroomingPageFeature.css';
import './css/components/ClientSaysComponent.css';
import './css/components/BoardingForm.css';
import './css/components/PrivacyPolicyComponent.css';
import './css/components/FaqsComponent.css';

import './css/buttons/ScrollTopButton.css';
import './css/buttons/ContactFixButton.css';

import './css/components/LandingPage.css';
import './css/components/LandingNav.css';

import './css/admin/AdminLogin.css';
import './css/admin/AdminHome.css';
import './css/admin/AdminNav.css';
import './css/admin/AdminBooking.css';

import HomePage from './pages/HomePage';
import GroomingPage from './pages/GroomingPage';
import ScrollToTop from './components/ScrollToTop';
import ScrollButton from './components/ScrollButton';
import PetBoardingPage from './pages/PetBoardingPage';
import AboutPage from './pages/AboutPage';
import ErrorPage from './pages/ErrorPage';
import ContactPage from './pages/ContactPage';
import ConfirmBooking from './pages/ConfirmBookingPage';
import ConfirmContactPage from './pages/ConfirmContactPage';
import BlogPage from './pages/BlogPage';
import AdminLogin from './pages/admin/AdminLogin';
import AdminHomePage from './pages/admin/AdminHomePage';
import AdminBookingPage from './pages/admin/AdminBookingPage';
import AdminContactUsPage from './pages/admin/AdminContactUsPage';
import BestDogForHome from './components/blog/singleBlog/BestDogForHome';
import TopPopularDogIndia from './components/blog/singleBlog/TopPopularDogIndia';
import FriendlinessDogBreeds from './components/blog/singleBlog/FriendlinessDogBreeds';
import IfDogCoughing from './components/blog/singleBlog/IfDogCoughing';
import SpotAndTreatDogAllergies from './components/blog/singleBlog/SpotAndTreatDogAllergies';
import NutritionAffectDog from './components/blog/singleBlog/NutritionAffectDog';
import DogHairLoss from './components/blog/singleBlog/DogHairLoss';
import LocationPage from './pages/LocationPage';
import GalleryPage from './pages/GalleryPage';
import DogNeuterSpay from './components/blog/singleBlog/DogNeuterSpay';
// import OtherPage from './pages/OtherPage';
import DogTeethCare from './components/blog/singleBlog/DogTeethCare';
import SummerCanBeCruelonYourPets from './components/blog/singleBlog/SummerCanBeCruelonYourPets';
import ZeroTrimDogHairInSummer from './components/blog/singleBlog/ZeroTrimDogHairInSummer';
import IsWheatHarmfulForDog from './components/blog/singleBlog/IsWheatHarmfulForDog';
import PuppiesUseTheirWhiningCryingToGetTreats from './components/blog/singleBlog/PuppiesUseTheirWhiningCryingToGetTreats';
import HydratedFruitAndVeggiesfor from './components/blog/singleBlog/HydratedFruitAndVeggiesfor';
import DebunkingMythsAndUnderstanding from './components/blog/singleBlog/DebunkingMythsAndUnderstanding';
import PetRegularDeworming from './components/blog/singleBlog/PetRegularDeworming';
import VitalRoleOfHydration from './components/blog/singleBlog/VitalRoleOfHydration';
import WhyBodyCoatOrFur from './components/blog/singleBlog/WhyBodyCoatOrFur';
import WhySlowFeeders from './components/blog/singleBlog/WhySlowFeeders';
import BewareHarmfulTreats from './components/blog/singleBlog/BewareHarmfulTreats';
import HowCanStopFleas from './components/blog/singleBlog/HowCanStopFleas';
import EarInfection from './components/blog/singleBlog/EarInfection';
import PetGroomingPage from './pages/PetGroomingPage';
import CommonGroomingMistakes from './components/blog/singleBlog/CommonGroomingMistakes';
import EssentialGroomingTips from './components/blog/singleBlog/EssentialGroomingTips ';
import BathingYourPet from './components/blog/singleBlog/BathingYourPet';
import DogBestShampoosAndConditioners from './components/blog/singleBlog/DogBestShampoosAndConditioners';
import CommonGroomingChallenges from './components/blog/singleBlog/CommonGroomingChallenges';
import RelaxingSpaDayExperience from './components/blog/singleBlog/RelaxingSpaDayExperience';
import DIYCatGrooming from './components/blog/singleBlog/DIYCatGrooming';
import DIYDogBathing from './components/blog/singleBlog/DIYDogBathing';
import DIYEarCleaning from './components/blog/singleBlog/DIYEarCleaning';
import EssentialGroomingSupplies from './components/blog/singleBlog/EssentialGroomingSupplies';
import DoubleCoatedBreeds from './components/blog/singleBlog/DoubleCoatedBreeds';
import PetsWithAllergies from './components/blog/singleBlog/PetsWithAllergies';
import PetsAnxiety from './components/blog/singleBlog/PetsAnxiety';
import PetArthritis from './components/blog/singleBlog/PetArthritis';
import PetsBehavioralIssues from './components/blog/singleBlog/PetsBehavioralIssues';
import PetsWithDisabilities from './components/blog/singleBlog/PetsWithDisabilities';
import PetsMobilityIssues from './components/blog/singleBlog/PetsMobilityIssues';
import PetsShortHair from './components/blog/singleBlog/PetsShortHair';
import PetsSkinConditions from './components/blog/singleBlog/PetsSkinConditions';
import PetsSpecialNeeds from './components/blog/singleBlog/PetsSpecialNeeds';
import PetsThickCoats from './components/blog/singleBlog/PetsThickCoats';
import TipsForSmallBreedDogs from './components/blog/singleBlog/TipsForSmallBreedDogs';
import GroomingTools from './components/blog/singleBlog/GroomingTools';
import FleaAndTickPrevention from './components/blog/singleBlog/FleaAndTickPrevention';
import PetSeasonalChanges from './components/blog/singleBlog/PetSeasonalChanges';
import PetForShow from './components/blog/singleBlog/PetForShow';
import PetForSpecialOccasions from './components/blog/singleBlog/PetForSpecialOccasions';
import PetForSpring from './components/blog/singleBlog/PetForSpring';
import PetForSummer from './components/blog/singleBlog/PetForSummer';
import PetForChangingSeasons from './components/blog/singleBlog/PetForChangingSeasons';
import PetForWinter from './components/blog/singleBlog/PetForWinter';
import GroomingStrengthenBond from './components/blog/singleBlog/GroomingStrengthenBond';
import GroomYourPet from './components/blog/singleBlog/GroomYourPet';
import BrushPetTeeth from './components/blog/singleBlog/BrushPetTeeth';
import CalmAnAnxiousPet from './components/blog/singleBlog/CalmAnAnxiousPet';
import RightBrushForPet from './components/blog/singleBlog/RightBrushForPet';
import RightGroomingProducts from './components/blog/singleBlog/RightGroomingProducts';
import RightGroomingSalon from './components/blog/singleBlog/RightGroomingSalon';
import DoubleCoatSheddingTips from './components/blog/singleBlog/DoubleCoatSheddingTips';
import GroomPetWithAnxiety from './components/blog/singleBlog/GroomPetWithAnxiety';
import PetBehavioralIssues from './components/blog/singleBlog/PetBehavioralIssues';
import PetWithFearAnxiety from './components/blog/singleBlog/PetWithFearAnxiety';
import PetWithLongHair from './components/blog/singleBlog/PetWithLongHair';
import MatsAndTangles from './components/blog/singleBlog/MatsAndTangles';
import PetMobilityIssues from './components/blog/singleBlog/PetMobilityIssues';
import PetSensitiveSkin from './components/blog/singleBlog/PetSensitiveSkin';
import PetThickFur from './components/blog/singleBlog/PetThickFur';
import HandleBathTime from './components/blog/singleBlog/HandleBathTime';
import HandleMatsAndTangles from './components/blog/singleBlog/HandleMatsAndTangles';
import HandleShedding from './components/blog/singleBlog/HandleShedding';
import PetToGrooming from './components/blog/singleBlog/PetToGrooming';
import KeepPetCalm from './components/blog/singleBlog/KeepPetCalm';
import RemoveMatsAndTangles from './components/blog/singleBlog/RemoveMatsAndTangles';
import DogMuzzle from './components/blog/singleBlog/DogMuzzle';
import UltimateGuide from './components/blog/singleBlog/UltimateGuide';
import ChooseDoorstepGrooming from './components/blog/singleBlog/ChooseDoorstepGrooming';
import OtherPage from './pages/OtherPage';
import DoorstepGroomingSession from './components/blog/singleBlog/DoorstepGroomingSession';
import PetHairCutPage from './pages/PetHairCutPage';
import DogGroomingPage from './pages/DogGroomingPage';
import CatGroomingPage from './pages/CatGroomingPage';
import DogBoardingPage from './pages/DogBoardingPage';
import CatBoardingPage from './pages/CatBoardingPage';
import DogOverweight from './components/blog/singleBlog/DogOverweight';
import SeasonalGroomingTips from './components/blog/singleBlog/SeasonalGroomingTips';
import ContactFixButton from './components/ContactFixButton';
import BookAppointmentPage from './pages/BookAppointmentPage';
import LandingPage from './pages/LandingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
// import DogBoardingFormPage from './pages/DogBoardingFormPage';
// import CatBoardingFormPage from './pages/CatBoardingFormPage';


const App = () => {
  return (
    <div className='web-app'>
      <BrowserRouter>
        <ScrollToTop />
        <ContactFixButton />

        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/book-an-appointment' element={<BookAppointmentPage />}></Route>

          <Route path='/price' element={<GroomingPage />}></Route>
          {/* <Route path='/pet-boarding' element={<PetBoardingPage />}></Route> */}

          {/* <Route path='/dog-boarding' element={<DogBoardingPage />}></Route> */}
          {/* <Route path='/cat-boarding' element={<CatBoardingPage />}></Route> */}

          <Route path='/pet-grooming' element={<PetGroomingPage />}></Route>
          <Route path='/pet-hair-cutting' element={<PetHairCutPage />}></Route>

          <Route path='/dog-grooming' element={<DogGroomingPage />}></Route>
          <Route path='/cat-grooming' element={<CatGroomingPage />}></Route>

          <Route path='/contact' element={<ContactPage />}></Route>
          <Route path='/about' element={<AboutPage />}></Route>
          <Route path='/*' element={<ErrorPage />}></Route>
          <Route path='/booking-confirm' element={<ConfirmBooking />}></Route>
          {/* <Route path='/thanks' element={<ConfirmBooking />}></Route> */}
          <Route path='/thank-you' element={<ConfirmBooking />}></Route>
          <Route path='/contact-confirm' element={<ConfirmContactPage />}></Route>
          <Route path='/gallery' element={<GalleryPage />}></Route>

          <Route path='/privacy-policy' element={<PrivacyPolicyPage />}></Route>
          
          {/* <Route path='/other' element={<OtherPage />}></Route> */}
          {/* <Route path='/dog-boarding' element={<DogBoardingFormPage />}></Route>
          <Route path='/cat-boarding'  element={<CatBoardingFormPage />}></Route> */}


          {/* <Route path='/delhi' element={<LandingPage location="delhi" />}></Route> */}


          <Route path='/pet-grooming-at-home/delhi' element={<LandingPage location="Delhi" />}></Route>
          <Route path='/pet-grooming-at-home/delhi-ncr' element={<LandingPage location="Delhi NCR" />}></Route>
          <Route path='/pet-grooming-at-home/noida' element={<LandingPage location="Noida"/>}></Route>
          <Route path='/pet-grooming-at-home/greater-noida' element={<LandingPage location="Greater Noida"/>}></Route>
          <Route path='/pet-grooming-at-home/gurugram' element={<LandingPage location="Gurugram"/>}></Route>
          <Route path='/pet-grooming-at-home/ghaziabad' element={<LandingPage location="Ghaziabad"/>}></Route>
          <Route path='/pet-grooming-at-home/faridabad' element={<LandingPage location="Faridabad"/>}></Route>


          <Route path='/pet-grooming-at-home/lodhi-colony-delhi' element={<LocationPage location="lodhi colony, delhi"/>}></Route>
          <Route path='/pet-grooming-at-home/chattarpur-delhi' element={<LocationPage location="chattarpur, delhi"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-36-greater-noida' element={<LocationPage location="sector-36, greater noida"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-143b-noida' element={<LocationPage location="sector-143B, noida"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-99-noida' element={<LocationPage location="sector-99, noida"/>}></Route>
          
          
          <Route path='/pet-grooming-at-home/ganga-nagar-meerut' element={<LocationPage location="ganga nagar, meerut"/>}></Route>
          <Route path='/pet-grooming-at-home/adhoiwala-dehradun' element={<LocationPage location="adhoiwala dehradun"/>}></Route>
          <Route path='/pet-grooming-at-home/sikandra-agra' element={<LocationPage location="sikandra agra"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-43-chandigarh' element={<LocationPage location="sector-43, chandigarh"/>}></Route>

          
          <Route path='/pet-grooming-at-home/uttam-nagar-delhi' element={<LocationPage location="uttam-nagar, delhi"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-48-faridabad' element={<LocationPage location="sector-48, faridabad"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-18-noida' element={<LocationPage location="sector-18, noida"/>}></Route>
          <Route path='/pet-grooming-at-home/laxmi-nagar-delhi' element={<LocationPage location="laxmi nagar, delhi"/>}></Route>
          <Route path='/pet-grooming-at-home/fatehpur-taga-faridabad' element={<LocationPage location="fatehpur taga, faridabad"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-143b-noida' element={<LocationPage location="sector-143B, noida"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-1-bisrakh-noida' element={<LocationPage location="sector-1, bisrakh, noida"/>}></Route>
          <Route path='/pet-grooming-at-home/noida-west' element={<LocationPage location="noida west"/>}></Route>
          <Route path='/pet-grooming-at-home/sector-16b-dwarka' element={<LocationPage location="sector-16B, dwarka"/>}></Route>



          {/* <Route path='/pet-boarding' element={<BoardingPageComponent />}></Route> */}

          <Route path='/blog' element={<BlogPage />}></Route>
          <Route path='/blog/best-dog-for-home' element={<BestDogForHome />}></Route>
          <Route path='/blog/top-popular-dog-india' element={<TopPopularDogIndia />}></Route>
          <Route path='/blog/friendliness-dog-breeds' element={<FriendlinessDogBreeds />}></Route>
          <Route path='/blog/if-dog-coughing' element={<IfDogCoughing />}></Route>
          <Route path='/blog/spot-and-treat-dog-allergies' element={<SpotAndTreatDogAllergies />}></Route>
          <Route path='/blog/nutrition-affect-dog' element={<NutritionAffectDog />}></Route>
          <Route path='/blog/dog-hair-loss' element={<DogHairLoss />}></Route>
          <Route path='/blog/dog-neuter-spay' element={<DogNeuterSpay />}></Route>
          <Route path='/blog/dog-teeth-care' element={<DogTeethCare />}></Route>
          <Route path='/blog/summer-can-be-cruelon-your-pets' element={<SummerCanBeCruelonYourPets />}></Route>
          <Route path='/blog/zero-trim-dog-hair-in-summer' element={<ZeroTrimDogHairInSummer />}></Route>
          <Route path='/blog/is-wheat-harmful-for-dog' element={<IsWheatHarmfulForDog />}></Route>
          <Route path='/blog/puppies-whining-crying' element={<PuppiesUseTheirWhiningCryingToGetTreats />}></Route>
          <Route path='/blog/hydrated-fruit-and-veggies' element={<HydratedFruitAndVeggiesfor />}></Route>
          <Route path='/blog/debunking-myths-and-understanding' element={<DebunkingMythsAndUnderstanding />}></Route>
          <Route path='/blog/pet-regular-deworming' element={<PetRegularDeworming />}></Route>
          <Route path='/blog/vital-role-of-hydration' element={<VitalRoleOfHydration />}></Route>
          <Route path='/blog/why-body-coat-or-fur' element={<WhyBodyCoatOrFur />}></Route>
          <Route path='/blog/why-slow-feeders' element={<WhySlowFeeders />}></Route>
          <Route path='/blog/beware-harmful-treats' element={<BewareHarmfulTreats />}></Route>
          <Route path='/blog/how-can-stop-fleas' element={<HowCanStopFleas />}></Route>
          <Route path='/blog/ear-infection' element={<EarInfection />}></Route>
          <Route path='/blog/common-grooming-mistakes' element={<CommonGroomingMistakes />}></Route>
          <Route path='/blog/essential-grooming-tips' element={<EssentialGroomingTips />}></Route>
          <Route path='/blog/bathing-your-pet' element={<BathingYourPet />}></Route>
          <Route path='/blog/dogs-best-shampoos-and-conditioners' element={<DogBestShampoosAndConditioners />}></Route>
          <Route path='/blog/common-grooming-challenges' element={<CommonGroomingChallenges />}></Route>
          <Route path='/blog/relaxing-spa-day-experience' element={<RelaxingSpaDayExperience />}></Route>
          <Route path='/blog/diy-cat-grooming' element={<DIYCatGrooming />}></Route>
          <Route path='/blog/diy-dog-bathing' element={<DIYDogBathing  />}></Route>
          <Route path='/blog/diy-ear-cleaning' element={<DIYEarCleaning  />}></Route>
          <Route path='/blog/essential-grooming-supplies' element={<EssentialGroomingSupplies  />}></Route>
          <Route path='/blog/double-coated-breeds' element={<DoubleCoatedBreeds  />}></Route>
          <Route path='/blog/pets-with-allergies' element={<PetsWithAllergies  />}></Route>
          <Route path='/blog/pets-anxiety' element={<PetsAnxiety  />}></Route>
          <Route path='/blog/pets-arthritis' element={<PetArthritis  />}></Route>
          <Route path='/blog/pets-behavioral-issues' element={<PetsBehavioralIssues  />}></Route>
          <Route path='/blog/pets-with-disabilities' element={<PetsWithDisabilities  />}></Route>
          <Route path='/blog/pets-mobility-issues' element={<PetsMobilityIssues  />}></Route>
          <Route path='/blog/pets-short-hair' element={<PetsShortHair  />}></Route>
          <Route path='/blog/pets-skin-conditions' element={<PetsSkinConditions  />}></Route>
          <Route path='/blog/pets-special-needs' element={<PetsSpecialNeeds  />}></Route>
          <Route path='/blog/pets-thick-coats' element={<PetsThickCoats  />}></Route>
          <Route path='/blog/tips-for-small-breed-dogs' element={<TipsForSmallBreedDogs  />}></Route>
          <Route path='/blog/grooming-tools' element={<GroomingTools  />}></Route>
          <Route path='/blog/flea-and-tick-prevention' element={<FleaAndTickPrevention  />}></Route>
          <Route path='/blog/pet-for-seasonal-changes' element={<PetSeasonalChanges  />}></Route>
          <Route path='/blog/grooming-your-pet-for-show' element={<PetForShow />}></Route>
          <Route path='/blog/grooming-your-pet-for-special-occasions' element={<PetForSpecialOccasions />}></Route>
          <Route path='/blog/grooming-your-pet-for-spring' element={<PetForSpring />}></Route>
          <Route path='/blog/grooming-your-pet-for-summer' element={<PetForSummer />}></Route>
          <Route path='/blog/grooming-your-pet-for-changing-season' element={<PetForChangingSeasons />}></Route>
          <Route path='/blog/grooming-your-pet-for-winter' element={<PetForWinter />}></Route>
          <Route path='/blog/grooming-can-strengthen-the-bond' element={<GroomingStrengthenBond />}></Route>
          <Route path='/blog/how-often-should-you-groom-your-pet' element={<GroomYourPet />}></Route>
          <Route path='/blog/how-to-brush-your-pet-teeth' element={<BrushPetTeeth />}></Route>
          <Route path='/blog/how-to-calm-an-anxious-pet-during-grooming-sessions' element={<CalmAnAnxiousPet />}></Route>
          <Route path='/blog/right-brush-for-your-pet' element={<RightBrushForPet />}></Route>
          <Route path='/blog/right-grooming-products-for-your-pet' element={<RightGroomingProducts />}></Route>
          <Route path='/blog/choose-right-grooming-salon-for-your-pet' element={<RightGroomingSalon />}></Route>
          <Route path='/blog/groom-pet-with-double-coat-shedding-tips' element={<DoubleCoatSheddingTips />}></Route>
          <Route path='/blog/how-to-groom-pet-with-anxiety' element={<GroomPetWithAnxiety />}></Route>
          <Route path='/blog/groom-pet-with-behavioral-issues' element={<PetBehavioralIssues />}></Route>
          <Route path='/blog/groom-pet-with-fear-or-anxiety' element={<PetWithFearAnxiety />}></Route>
          <Route path='/blog/groom-pet-with-long-hair' element={<PetWithLongHair />}></Route>
          <Route path='/blog/groom-pet-with-mats-and-tangles' element={<MatsAndTangles />}></Route>
          <Route path='/blog/groom-pet-with-mobility-issues' element={<PetMobilityIssues />}></Route>
          <Route path='/blog/groom-pet-with-sensitive-skin' element={<PetSensitiveSkin />}></Route>
          <Route path='/blog/groom-pet-with-thick-fur' element={<PetThickFur />}></Route>
          <Route path='/blog/handle-bath-time-with-nervous-pet-tips-and-tricks' element={<HandleBathTime />}></Route>
          <Route path='/blog/handle-mats-and-tangles' element={<HandleMatsAndTangles />}></Route>
          <Route path='/blog/handle-shedding-tips-for-managing-pet-hair' element={<HandleShedding />}></Route>
          <Route path='/blog/how-to-introduce-your-pet-to-grooming' element={<PetToGrooming />}></Route>
          <Route path='/blog/how-to-keep-your-pet-calm-during-grooming-sessions' element={<KeepPetCalm />}></Route>
          <Route path='/blog/how-to-remove-mats-and-tangles-safely' element={<RemoveMatsAndTangles />}></Route>
          <Route path='/blog/dog-muzzle' element={<DogMuzzle />}></Route>
          <Route path='/blog/ultimate-guide-to-at-home-pet-grooming' element={<UltimateGuide />}></Route>
          <Route path='/blog/top-5-reasons-to-choose-doorstep-grooming-for-your-pet' element={<ChooseDoorstepGrooming />}></Route>
          <Route path='/blog/prepare-your-pet-for-doorstep-grooming-session' element={<DoorstepGroomingSession />}></Route>
          <Route path='/blog/how-to-know-your-dog-is-overweight' element={<DogOverweight />}></Route>
          <Route path='/blog/seasonal-grooming-tips-for-dogs-and-cats' element={<SeasonalGroomingTips />}></Route>
















          {/* ----------------------------------------------------------------------------------------------- */}

          <Route path='/admin' element={<AdminLogin />}></Route>
          <Route path='/admin/home' element={<AdminHomePage />}></Route>
          <Route path='/admin/bookings' element={<AdminBookingPage />}></Route>
          <Route path='/admin/contact-us' element={<AdminContactUsPage />}></Route>




          {/* ----------------------------------------------------------------------------------------------- */}
          <Route path='/pet-grooming-for-cats' element={<HomePage />}></Route>
          <Route path='/cat-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/cat-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-bath-service-near-me' element={<HomePage />}></Route>
          <Route path='/home-cat-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-places-near-me' element={<HomePage />}></Route>
          <Route path='/cat-haircuts-near-me' element={<HomePage />}></Route>
          <Route path='/cat-bath-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-nail-clipping-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-home-service' element={<HomePage />}></Route>
          <Route path='/cat-grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/cat-spa-near-me' element={<HomePage />}></Route>
          <Route path='/persian-cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-service-near-me' element={<HomePage />}></Route>
          <Route path='/cat-groomer' element={<HomePage />}></Route>
          <Route path='/cat-grooming-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/cat-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-hairdresser-near-me' element={<HomePage />}></Route>
          <Route path='/cat-haircuts' element={<HomePage />}></Route>
          <Route path='/mobile-cat-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-at-home' element={<HomePage />}></Route>
          <Route path='/cat-nail-clipping-service-near-me' element={<HomePage />}></Route>
          <Route path='/cat-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/cat-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-cat-grooming' element={<HomePage />}></Route>
          <Route path='/cat-groomers-that-come-to-your-house' element={<HomePage />}></Route>
          <Route path='/professional-cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/best-cat-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/my-cat-is-grooming-me' element={<HomePage />}></Route>
          <Route path='/persian-cat-grooming-at-home' element={<HomePage />}></Route>
          <Route path='/cat-grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/cat-haircut' element={<HomePage />}></Route>
          <Route path='/cat-cleaning-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/cat-parlour' element={<HomePage />}></Route>
          <Route path='/cat-salon-near-me' element={<HomePage />}></Route>
          <Route path='/kitten-grooming-services' element={<HomePage />}></Route>
          <Route path='/cat-grooming-charges' element={<HomePage />}></Route>
          <Route path='/cat-hair-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/kitten-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-bath-near-me' element={<HomePage />}></Route>
          <Route path='/cat-bathing-services-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-services' element={<HomePage />}></Route>
          <Route path='/persian-cat-grooming' element={<HomePage />}></Route>
          <Route path='/cat-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-services-near-delhi' element={<HomePage />}></Route>
          <Route path='/cat-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/cat-parlour' element={<HomePage />}></Route>
          <Route path='/cat-hair-grooming' element={<HomePage />}></Route>
          <Route path='/cat-hair-salon' element={<HomePage />}></Route>
          <Route path='/cat-salon-near-me' element={<HomePage />}></Route>
          <Route path='/senior-cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-services-at-home' element={<HomePage />}></Route>
          <Route path='/mobile-cat-grooming-in-my-area' element={<HomePage />}></Route>
          <Route path='/home-grooming-service-for-cats' element={<HomePage />}></Route>
          <Route path='/kitty-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-hair-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-grooming-service' element={<HomePage />}></Route>
          <Route path='/cat-hair-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/cat-sap-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-your-cat' element={<HomePage />}></Route>
          <Route path='/house-call-cat-grooming' element={<HomePage />}></Route>
          <Route path='/cat-groomer-home-visit' element={<HomePage />}></Route>
          <Route path='/cat-spa-near-me' element={<HomePage />}></Route>
          <Route path='/cat-cutting-hair' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming-for-cats' element={<HomePage />}></Route>
          <Route path='/nail-trimmer-for-cats' element={<HomePage />}></Route>
          <Route path='/cat-bath-service' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming-near-me-cats' element={<HomePage />}></Route>
          <Route path='/cat-grooming-salon' element={<HomePage />}></Route>
          <Route path='/cheap-cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-salon-at-home' element={<HomePage />}></Route>
          <Route path='/cat-wash-near-me' element={<HomePage />}></Route>
          <Route path='/cat-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/cat-bathing' element={<HomePage />}></Route>
          <Route path='/cat-groomer-in-my-area' element={<HomePage />}></Route>
          <Route path='/cat-washing' element={<HomePage />}></Route>
          <Route path='/cat-salon' element={<HomePage />}></Route>
          <Route path='/cats-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-fur-trimming' element={<HomePage />}></Route>
          <Route path='/cat-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/cat-groomers-that-come-to-your-house-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-pet-services-for-cats' element={<HomePage />}></Route>
          <Route path='/cat-parlour-at-home' element={<HomePage />}></Route>
          <Route path='/cat-sap-at-home' element={<HomePage />}></Route>
          <Route path='/cat-cleaning' element={<HomePage />}></Route>
          <Route path='/cats-grooming' element={<HomePage />}></Route>
          <Route path='/cat-home-grooming' element={<HomePage />}></Route>
          <Route path='/cat-bath-near-me' element={<HomePage />}></Route>
          <Route path='/cat-hair-cut' element={<HomePage />}></Route>
          <Route path='/cat-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/cat-hair-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/cat-groomers-in-my-area' element={<HomePage />}></Route>
          <Route path='/cat-grooming-services' element={<HomePage />}></Route>
          <Route path='/cat-salon-near-me' element={<HomePage />}></Route>
          <Route path='/cat-groomers' element={<HomePage />}></Route>



          <Route path='/dog-house-pet-salon' element={<HomePage />}></Route>
          <Route path='/doggy-parlour' element={<HomePage />}></Route>
          <Route path='/dog-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-dog-nail-clipping' element={<HomePage />}></Route>
          <Route path='/dog-grooming-price-list' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming-prices' element={<HomePage />}></Route>
          <Route path='/dog-spa-mobile-grooming' element={<HomePage />}></Route>
          <Route path='/dog-bath-home-service' element={<HomePage />}></Route>
          <Route path='/dog-grooming-salons-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-puppy-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-delhi' element={<HomePage />}></Route>
          <Route path='/dog-grooming-parlour' element={<HomePage />}></Route>
          <Route path='/professional-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-groomers-near-me-reviews' element={<HomePage />}></Route>
          <Route path='/dog-groomers-that-come-to-your-house-near-me' element={<HomePage />}></Route>
          <Route path='/dog-cutting' element={<HomePage />}></Route>
          <Route path='/local-dog-groomers' element={<HomePage />}></Route>
          <Route path='/dog-bath-at-home-service' element={<HomePage />}></Route>
          <Route path='/dog-cleaning-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-nail-trimming' element={<HomePage />}></Route>
          <Route path='/best-dog-grooming-in-delhi' element={<HomePage />}></Route>
          <Route path='/dog-wash-service-near-me' element={<HomePage />}></Route>
          <Route path='/in-home-grooming-for-dogs' element={<HomePage />}></Route>
          <Route path='/ome-dog-grooming-services' element={<HomePage />}></Route>
          <Route path='/best-rated-dog-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-gurgaon' element={<HomePage />}></Route>
          <Route path='/dog-haircut' element={<HomePage />}></Route>
          <Route path='/hair-of-the-dog-grooming-salon' element={<HomePage />}></Route>
          <Route path='/dog-grooming-noida' element={<HomePage />}></Route>
          <Route path='/local-mobile-dog-groomers' element={<HomePage />}></Route>
          <Route path='/grooming-dog-salon' element={<HomePage />}></Route>
          <Route path='/the-hair-of-the-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-baths-for-home' element={<HomePage />}></Route>
          <Route path='/dog-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-groomers-around-me' element={<HomePage />}></Route>
          <Route path='/the-best-dog-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/dog-groomers-that-come-to-your-house' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home' element={<HomePage />}></Route>
          <Route path='/puppy-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-prices' element={<HomePage />}></Route>
          <Route path='/dog-nail-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-services' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-dog-groomers-in-my-area' element={<HomePage />}></Route>
          <Route path='/home-grooming-service-for-dogs' element={<HomePage />}></Route>
          <Route path='/best-puppy-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-centre-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-grooming-dog-salon' element={<HomePage />}></Route>
          <Route path='/dog-nail-trimming-services-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-hair-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/the-dog-grooming-shop' element={<HomePage />}></Route>
          <Route path='/dog-grooming-book-online' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming-prices-near-me' element={<HomePage />}></Route>
          <Route path='/at-home-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-dog-bathing-service' element={<HomePage />}></Route>
          <Route path='/hair-of-the-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-appointment' element={<HomePage />}></Route>
          <Route path='/mobile-dog-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/best-mobile-dog-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/dog-house-pet-grooming' element={<HomePage />}></Route>
          <Route path='/at-home-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-service-at-home' element={<HomePage />}></Route>
          <Route path='/dog-grooming-dwarka' element={<HomePage />}></Route>
          <Route path='/dog-grooming-greater-noida' element={<HomePage />}></Route>
          <Route path='/home-grooming-service-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-mobile-spa' element={<HomePage />}></Route>
          <Route path='/dog-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/fancy-dog-grooming-salon' element={<HomePage />}></Route>
          <Route path='/dog-hair-cut-at-home' element={<HomePage />}></Route>
          <Route path='/dog-grooming-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/haircut-dogs-near-me' element={<HomePage />}></Route>
          <Route path='/best-groomer-for-dogs-near-me' element={<HomePage />}></Route>
          <Route path='/dog-spa-price-list' element={<HomePage />}></Route>
          <Route path='/dog-hair-cutting-price-in-delhi' element={<HomePage />}></Route>
          <Route path='/dog-grooming-cheap-prices' element={<HomePage />}></Route>
          <Route path='/dog-nail-cutting-at-home' element={<HomePage />}></Route>
          <Route path='/best-dog-grooming-in-delhi' element={<HomePage />}></Route>
          <Route path='/dog-saloon-near-me' element={<HomePage />}></Route>
          <Route path='/salon-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-cleaning-services' element={<HomePage />}></Route>
          <Route path='/home-service-dog-grooming' element={<HomePage />}></Route>
          <Route path='/hair-cutter-for-dogs' element={<HomePage />}></Route>
          <Route path='/nearby-dog-grooming-services' element={<HomePage />}></Route>
          <Route path='/dog-haircut-shop-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-gurgaon' element={<HomePage />}></Route>
          <Route path='/dog-gromming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-wash-services' element={<HomePage />}></Route>
          <Route path='/dog-grooming-price-in-delhi' element={<HomePage />}></Route>
          <Route path='/dog-hair-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-charges' element={<HomePage />}></Route>
          <Route path='/dog-groomer-at-home' element={<HomePage />}></Route>
          <Route path='/dog-cleaning-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-center-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bath' element={<HomePage />}></Route>
          <Route path='/dog-wash-service-at-home' element={<HomePage />}></Route>
          <Route path='/dog-grooming-centre' element={<HomePage />}></Route>
          <Route path='/dog-grooming-cost-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hair-cut' element={<HomePage />}></Route>
          <Route path='/dog-spa-and-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-groomers-needed-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-near-me-home-service' element={<HomePage />}></Route>
          <Route path='/dog-hair-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/dog-wash-service-near-me' element={<HomePage />}></Route>
          <Route path='/dog-haircut' element={<HomePage />}></Route>
          <Route path='/haircut-for-dogs-at-home' element={<HomePage />}></Route>
          <Route path='/dog-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/best-groomer-for-dogs' element={<HomePage />}></Route>
          <Route path='/shih-tzu-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-hair-cutting-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/dogs-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/best-dog-grooming-places-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bath-at-home' element={<HomePage />}></Route>
          <Route path='/grooming-at-home-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-washing-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/dog-grooming-near-by' element={<HomePage />}></Route>
          <Route path='/best-dog-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-clipping' element={<HomePage />}></Route>
          <Route path='/mobile-pet-groomers-for-dogs' element={<HomePage />}></Route>
          <Route path='/best-mobile-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/door-to-door-dog-grooming' element={<HomePage />}></Route>
          <Route path='/mobile-dog-nail-trimming-services-near-me' element={<HomePage />}></Route>
          <Route path='/dog-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bath-near-me' element={<HomePage />}></Route>
          <Route path='/trimming-dog-hair' element={<HomePage />}></Route>
          <Route path='/dog-nail-clipping-service-near-me' element={<HomePage />}></Route>
          <Route path='/the-mobile-dog-groomers' element={<HomePage />}></Route>
          <Route path='/the-spa-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-price-list-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-clipping-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bathing-service' element={<HomePage />}></Route>
          <Route path='/cheap-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-dog-wash' element={<HomePage />}></Route>
          <Route path='/dog-hair-cutting-at-home' element={<HomePage />}></Route>
          <Route path='/dog-bathing-services-near-me' element={<HomePage />}></Route>
          <Route path='/pet-spa-near-me-for-dogs' element={<HomePage />}></Route>
          <Route path='/online-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-hair-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-home-service' element={<HomePage />}></Route>
          <Route path='/dog-grooming-from-home' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming-service' element={<HomePage />}></Route>
          <Route path='/dog-groomers-in-the-area' element={<HomePage />}></Route>
          <Route path='/dog-grooming-appointment-book' element={<HomePage />}></Route>
          <Route path='/in-home-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-your-dog-at-home' element={<HomePage />}></Route>
          <Route path='/home-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-places' element={<HomePage />}></Route>
          <Route path='/dog-grooming-shop-near-me' element={<HomePage />}></Route>
          <Route path='/dog-haircut-at-home' element={<HomePage />}></Route>
          <Route path='/grooming-near-me-for-dogs' element={<HomePage />}></Route>
          <Route path='/nail-clipping-dogs-near-me' element={<HomePage />}></Route>
          <Route path='/dog-house-groomer' element={<HomePage />}></Route>
          <Route path='/best-grooming-salon-for-dogs' element={<HomePage />}></Route>
          <Route path='/mobile-dog-washing-service' element={<HomePage />}></Route>
          <Route path='/dog-grooming-shops-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-spa-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bath-service-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-at-home-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-salon' element={<HomePage />}></Route>
          <Route path='/dog-bath-at-home' element={<HomePage />}></Route>
          <Route path='/dog-spa' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-clipping-service' element={<HomePage />}></Route>
          <Route path='/dog-grooming-prices-near-me' element={<HomePage />}></Route>
          <Route path='/dog-groomers-near-my-location' element={<HomePage />}></Route>
          <Route path='/mobile-pet-services-for-dogs' element={<HomePage />}></Route>
          <Route path='/the-dog-house-pet-salon' element={<HomePage />}></Route>
          <Route path='/dog-grooming-places-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-salon' element={<HomePage />}></Route>
          <Route path='/hair-of-the-dog-mobile-grooming' element={<HomePage />}></Route>
          <Route path='/dog-spa-at-home' element={<HomePage />}></Route>
          <Route path='/pets-at-home-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/the-dog-spa' element={<HomePage />}></Route>
          <Route path='/dog-grooming-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/home-grooming-dogs' element={<HomePage />}></Route>
          <Route path='/dog-spa-delhi' element={<HomePage />}></Route>
          <Route path='/dog-spa-services-at-home' element={<HomePage />}></Route>
          <Route path='/dog-bath-house' element={<HomePage />}></Route>
          <Route path='/dog-groomers-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/dog-spa-near-me' element={<HomePage />}></Route>
          <Route path='/dog-wash-at-home' element={<HomePage />}></Route>
          <Route path='/grooming-packages-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-faridabad' element={<HomePage />}></Route>
          <Route path='/doorstep-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-spa-noida' element={<HomePage />}></Route>
          <Route path='/mobile-dog-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hair-cut-service-at-home' element={<HomePage />}></Route>
          <Route path='/haircut-for-dogs-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bathing-service-at-home' element={<HomePage />}></Route>
          <Route path='/dog-spa' element={<HomePage />}></Route>
          <Route path='/mobile-dog-grooming' element={<HomePage />}></Route>
          <Route path='/mobile-dog-washers' element={<HomePage />}></Route>
          <Route path='/dog-grooming-ghaziabad' element={<HomePage />}></Route>
          <Route path='/dog-grooming-at-home-delhi' element={<HomePage />}></Route>
          <Route path='/dog-bathing-services' element={<HomePage />}></Route>
          <Route path='/dog-parlour' element={<HomePage />}></Route>
          <Route path='/dog-hair-trimming' element={<HomePage />}></Route>
          <Route path='/dog-groomers-open-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-near-me-at-home' element={<HomePage />}></Route>
          <Route path='/dog-hair-cutting-shop-near-me' element={<HomePage />}></Route>
          <Route path='/nearest-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-janakpuri' element={<HomePage />}></Route>
          <Route path='/dog-salons-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-around-me' element={<HomePage />}></Route>
          <Route path='/dog-bathing-at-home' element={<HomePage />}></Route>
          <Route path='/dog-bath-near-me' element={<HomePage />}></Route>
          <Route path='/nail-cutting-for-dogs' element={<HomePage />}></Route>
          <Route path='/cheapest-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-for-dogs-at-home' element={<HomePage />}></Route>
          <Route path='/dogs-grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-indirapuram' element={<HomePage />}></Route>
          <Route path='/dog-grooming-service' element={<HomePage />}></Route>
          <Route path='/dogs-salon-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hairstylist' element={<HomePage />}></Route>
          <Route path='/mobile-dog-spa-near-me' element={<HomePage />}></Route>
          <Route path='/dog-nail-clipping-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/dog-bathing-service-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-center-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hair-cutting-shop-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hairdresser' element={<HomePage />}></Route>
          <Route path='/mobile-spa-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hairdresser-near-me' element={<HomePage />}></Route>
          <Route path='/the-dog-house-grooming' element={<HomePage />}></Route>
          <Route path='/mobile-dog-wash-near-me' element={<HomePage />}></Route>
          <Route path='/professional-dog-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-grooming-near-me-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-groomers-in-my-area' element={<HomePage />}></Route>
          <Route path='/dog-groomers-nearby' element={<HomePage />}></Route>
          <Route path='/dog-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/dog-nail-trimming' element={<HomePage />}></Route>
          <Route path='/dog-grooming-bath' element={<HomePage />}></Route>
          <Route path='/local-mobile-dog-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/best-mobile-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-groomers-that-come-to-your-home' element={<HomePage />}></Route>
          <Route path='/best-dog-grooming' element={<HomePage />}></Route>
          <Route path='/dog-groomers-near-me-mobile' element={<HomePage />}></Route>
          <Route path='/best-dog-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/dog-grooming-near-me-prices' element={<HomePage />}></Route>
          <Route path='/dog-spa-gurgaon' element={<HomePage />}></Route>
          <Route path='/dog-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/dog-hair-cuts-near-me' element={<HomePage />}></Route>
          <Route path='/nearest-grooming-for-dogs' element={<HomePage />}></Route>
          <Route path='/dog-hair-salon-near-me' element={<HomePage />}></Route>
          <Route path='/home-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pretty-pets-mobile-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-gurgaon' element={<HomePage />}></Route>
          <Route path='/pets-salon' element={<HomePage />}></Route>
          <Route path='/pet-grooming-places-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-salon' element={<HomePage />}></Route>
          <Route path='/mobile-pet-spa-near-me' element={<HomePage />}></Route>
          <Route path='/pets-at-home-grooming' element={<HomePage />}></Route>
          <Route path='/professional-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-mobile-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-puppy-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/best-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/pet-bath-and-grooming' element={<HomePage />}></Route>
          <Route path='/pet-mobile-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-nail-trimming' element={<HomePage />}></Route>
          <Route path='/pets-at-home-grooming-prices' element={<HomePage />}></Route>
          <Route path='/pet-grooming-center-near-me' element={<HomePage />}></Route>
          <Route path='/pets-at-home-grooming-service' element={<HomePage />}></Route>
          <Route path='/grooming-dog-salon' element={<HomePage />}></Route>
          <Route path='/the-hair-of-the-dog-grooming' element={<HomePage />}></Route>
          <Route path='/professional-groomer' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/best-mobile-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-spa-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-nearby' element={<HomePage />}></Route>
          <Route path='/in-home-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/best-puppy-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming-in-delhi' element={<HomePage />}></Route>
          <Route path='/pet-grooming-home-service' element={<HomePage />}></Route>
          <Route path='/pet-hair-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-places' element={<HomePage />}></Route>
          <Route path='/pet-mobile-grooming-services' element={<HomePage />}></Route>
          <Route path='/grooming-places-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-services' element={<HomePage />}></Route>
          <Route path='/in-home-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-delhi' element={<HomePage />}></Route>
          <Route path='/grooming-mobile-service' element={<HomePage />}></Route>
          <Route path='/puppy-spa-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-online-booking' element={<HomePage />}></Route>
          <Route path='/shihtzu-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-pick-up-service' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-noida' element={<HomePage />}></Route>
          <Route path='/salon-for-pets-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-parlour-in-delhi' element={<HomePage />}></Route>
          <Route path='/pet-groomer-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming-in-delhi' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-doorstep' element={<HomePage />}></Route>
          <Route path='/pet-hair-stylist' element={<HomePage />}></Route>
          <Route path='/near-by-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-hair-salon' element={<HomePage />}></Route>
          <Route path='/pet-bathing-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-near-me-at-home' element={<HomePage />}></Route>
          <Route path='/pet-groomin-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-in-gurgaon' element={<HomePage />}></Route>
          <Route path='/pets-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/fluffy-pets-grooming' element={<HomePage />}></Route>
          <Route path='/nearest-pet-grooming-salon' element={<HomePage />}></Route>
          <Route path='/cheapest-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-washing' element={<HomePage />}></Route>
          <Route path='/pet-cleaning-services-near-me' element={<HomePage />}></Route>
          <Route path='/pet-saloon-near-me' element={<HomePage />}></Route>
          <Route path='/pet-hair-cutting' element={<HomePage />}></Route>
          <Route path='/best-pet-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/pets-grooming-center-near-me' element={<HomePage />}></Route>
          <Route path='/pet-spa' element={<HomePage />}></Route>
          <Route path='/grooming-puppy-near-me' element={<HomePage />}></Route>
          <Route path='/pet-hair-cleaning' element={<HomePage />}></Route>
          <Route path='/grooming-packages-noida' element={<HomePage />}></Route>
          <Route path='/best-shih-tzu-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-faridabad' element={<HomePage />}></Route>
          <Route path='/pet-grooming-online-booking' element={<HomePage />}></Route>
          <Route path='/at-home-mobile-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-salons-near-me' element={<HomePage />}></Route>
          <Route path='/fancy-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pets-grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/luxury-pet-grooming-salon' element={<HomePage />}></Route>
          <Route path='/home-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-at-doorstep' element={<HomePage />}></Route>
          <Route path='/pet-hair-cutting-near-me' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming' element={<HomePage />}></Route>
          <Route path='/grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/pet-bath-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-dwarka' element={<HomePage />}></Route>
          <Route path='/pets-grooming-salon' element={<HomePage />}></Route>
          <Route path='/pet-cleaning-near-me' element={<HomePage />}></Route>
          <Route path='/pet-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/pet-bath-near-me' element={<HomePage />}></Route>
          <Route path='/groomers-near-me' element={<HomePage />}></Route>
          <Route path='/the-pet-shop-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-delhi' element={<HomePage />}></Route>
          <Route path='/pet-grooming-in-delhi' element={<HomePage />}></Route>
          <Route path='/pet-bath-at-home' element={<HomePage />}></Route>
          <Route path='/professional-grooming' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming-services' element={<HomePage />}></Route>
          <Route path='/pet-groomers-that-come-to-your-house-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-salon-near-me' element={<HomePage />}></Route>
          <Route path='/home-groomers-near-me' element={<HomePage />}></Route>
          <Route path='/pet-haircut' element={<HomePage />}></Route>
          <Route path='/pet-salon-home-service' element={<HomePage />}></Route>
          <Route path='/at-your-door-mobile-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-faridabad' element={<HomePage />}></Route>
          <Route path='/groomers-that-come-to-your-house' element={<HomePage />}></Route>
          <Route path='/pet-grooming-near-me-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services' element={<HomePage />}></Route>
          <Route path='/pet-mobile-grooming' element={<HomePage />}></Route>
          <Route path='/home-grooming-service' element={<HomePage />}></Route>
          <Route path='/grooming-salons-near-me' element={<HomePage />}></Route>
          <Route path='/online-pet-grooming-services' element={<HomePage />}></Route>
          <Route path='/grooming-salon' element={<HomePage />}></Route>
          <Route path='/puppy-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/the-groomer-pet-spa' element={<HomePage />}></Route>
          <Route path='/professional-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-gurgaon' element={<HomePage />}></Route>
          <Route path='/pet-groomers-home-service' element={<HomePage />}></Route>
          <Route path='/pet-spa' element={<HomePage />}></Route>
          <Route path='/pet-groomers-in-my-area' element={<HomePage />}></Route>
          <Route path='/pet-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-shop-grooming' element={<HomePage />}></Route>
          <Route path='/pet-groomers-that-come-to-your-house' element={<HomePage />}></Route>
          <Route path='/pet-care-mobile-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-centre-near-me' element={<HomePage />}></Route>
          <Route path='/home-service-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-around-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/pets-at-home-dog-grooming' element={<HomePage />}></Route>
          <Route path='/pet-salon-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-pets-at-home' element={<HomePage />}></Route>
          <Route path='/groomers-in-my-area' element={<HomePage />}></Route>
          <Route path='/pet-grooming-in-noida' element={<HomePage />}></Route>
          <Route path='/doorstep-pet-grooming' element={<HomePage />}></Route>
          <Route path='/grooming-services-for-pets' element={<HomePage />}></Route>
          <Route path='/shih-tzu-hair-cutting' element={<HomePage />}></Route>
          <Route path='/pet-groomer-in-my-area' element={<HomePage />}></Route>
          <Route path='/shih-tzu-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-near-by' element={<HomePage />}></Route>
          <Route path='/pet-haircut' element={<HomePage />}></Route>
          <Route path='/pet-spa-at-home' element={<HomePage />}></Route>
          <Route path='/groomers-near-me' element={<HomePage />}></Route>
          <Route path='/pet-saloon' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-gurgaon' element={<HomePage />}></Route>
          <Route path='/pet-spa-near-me' element={<HomePage />}></Route>
          <Route path='/pets-grooming-shop-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-pet-at-home' element={<HomePage />}></Route>
          <Route path='/pet-wash-and-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-indirapuram' element={<HomePage />}></Route>
          <Route path='/pet-grooming-prices-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-center' element={<HomePage />}></Route>
          <Route path='/best-pet-spa-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-services-delhi' element={<HomePage />}></Route>
          <Route path='/pet-grooming-studio' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming' element={<HomePage />}></Route>
          <Route path='/fluffy-house-grooming' element={<HomePage />}></Route>
          <Route path='/urban-clap-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-home-services' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-delhi' element={<HomePage />}></Route>
          <Route path='/pet-nail-trimming-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-centre-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-greater-noida' element={<HomePage />}></Route>
          <Route path='/fluffy-puppy-grooming' element={<HomePage />}></Route>
          <Route path='/pet-groming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-centre-near-me' element={<HomePage />}></Route>
          <Route path='/pet-haircut-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-in-janakpuri' element={<HomePage />}></Route>
          <Route path='/pet-wash-at-home' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/deluxe-pet-grooming' element={<HomePage />}></Route>
          <Route path='/pet-haircut-at-home' element={<HomePage />}></Route>
          <Route path='/pet-groomers-home-service' element={<HomePage />}></Route>
          <Route path='/at-home-pet-grooming-services' element={<HomePage />}></Route>
          <Route path='/pet-service-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-faridabad' element={<HomePage />}></Route>
          <Route path='/pet-grooming-rajouri-garden' element={<HomePage />}></Route>
          <Route path='/parlour-at-home' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-noida' element={<HomePage />}></Route>
          <Route path='/pet-salons' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-in-delhi' element={<HomePage />}></Route>
          <Route path='/home-pet-grooming-services-near-me' element={<HomePage />}></Route>
          <Route path='/pet-wash-near-me' element={<HomePage />}></Route>
          <Route path='/pet-cleaning-services' element={<HomePage />}></Route>
          <Route path='/pet-grooming-packages' element={<HomePage />}></Route>
          <Route path='/groomer-pet-near-me' element={<HomePage />}></Route>
          <Route path='/pet-stylist-near-me' element={<HomePage />}></Route>
          <Route path='/pet-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-home-service-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-prices-near-me' element={<HomePage />}></Route>
          <Route path='/puppy-grooming' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home' element={<HomePage />}></Route>
          <Route path='/puppy-nail-clipping' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/pets-at-home-haircut' element={<HomePage />}></Route>
          <Route path='/puppy-haircut' element={<HomePage />}></Route>
          <Route path='/grooming-appointment' element={<HomePage />}></Route>
          <Route path='/puppy-groom-pets-at-home' element={<HomePage />}></Route>
          <Route path='/pet-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-delhi' element={<HomePage />}></Route>
          <Route path='/pet-bath' element={<HomePage />}></Route>
          <Route path='/best-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-groomer-near-me' element={<HomePage />}></Route>
          <Route path='/grooming-parlour-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-at-home-near-me' element={<HomePage />}></Route>
          <Route path='/mobile-pet-grooming-near-me' element={<HomePage />}></Route>
          <Route path='/pet-grooming-in-delhi' element={<HomePage />}></Route>



{/* ----------------------------------------------------------------------------------------------- */}
          <Route path='/index.php' element={<HomePage />}></Route>
          <Route path='/dog-and-cat-fur-fresh-spa' element={<PetGroomingPage />}></Route>
          <Route path='/dog-basic-grooming' element={<DogGroomingPage />}></Route>
          <Route path='/dog-designer-hair-cutting' element={<DogGroomingPage />}></Route>
          <Route path='/dog-deluxe-grooming' element={<DogGroomingPage />}></Route>
          <Route path='/greater-noida' element={<LocationPage location="greater noida"/>}></Route>
          <Route path='/cat-designer-hair-cutting' element={<CatGroomingPage />}></Route>
          <Route path='/gurugram' element={<LocationPage location="gurugram"/>}></Route>
          <Route path='/faridabad' element={<LocationPage location="faridabad"/>}></Route>
          <Route path='/pet-boarding-service-noida-ncr' element={<LocationPage location="noida"/>}></Route>
          <Route path='/dog-full-body-grooming' element={<DogGroomingPage />}></Route>
          <Route path='/dog-and-cat-pamper-paws' element={<PetGroomingPage />}></Route>
          <Route path='/cat-full-body-grooming' element={<CatGroomingPage />}></Route>
          <Route path='/cat-basic-grooming' element={<CatGroomingPage />}></Route>
          <Route path='/ghaziabad' element={<LocationPage location="ghaziabad"/>}></Route>
          <Route path='/cat-deluxe-grooming' element={<CatGroomingPage />}></Route>
          <Route path='/gallery-single.html' element={<GalleryPage />}></Route>
          <Route path='/contact.php' element={<ContactPage />}></Route>
          <Route path='/gurgaon' element={<LocationPage location="gurgaon"/>}></Route>
          <Route path='/dog-cat-bark-and-bath' element={<PetGroomingPage />}></Route>
          <Route path='/gallery.php' element={<GalleryPage />}></Route>
          <Route path='/about.php' element={<AboutPage />}></Route>
          <Route path='/price.php' element={<GroomingPage />}></Route>


















          <Route path='/' element={<HomePage />}></Route>


          <Route path='/cat-hair-cutting' element={<GroomingPage />}></Route>
          <Route path='/cat-grooming-near-me-prices' element={<GroomingPage />}></Route>
          {/* <Route path='/' element={<GroomingPage />}></Route> */}





        </Routes>
        <ScrollButton />
      </BrowserRouter>


    </div>
  )
}

export default App