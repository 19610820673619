import React, { useEffect } from 'react'
import LandingNavbar from '../components/LandingNavbar'
import LandingGroomingComponent from '../components/LandingGroomingComponent'
import NumberIncrement from '../components/NumberIncrement'
import LandingHowWeWork from '../components/LandingHowWeWork'
import LandingOurLocations from '../components/LandingOurLocations'
import WhyFt from '../components/WhyFt'
import LandingTestimonial from '../components/LandingTestimonial'
import LandingNavTop from '../components/LandingNavTop'
import LandingHeader from '../components/LandingHeader'
import LandingFooter from '../components/LandingFooter'
import FaqsComponent from '../components/FaqsComponent'
import GroomingPackageComponent from '../components/GroomingPackageComponent'

const LandingPage = ({location}) => {

  useEffect(() => {
    // Function to scroll to the element with the ID from the URL hash
    const handleHashChange = () => {
      const elementId = window.location.hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Initial scroll on load
    handleHashChange();

    // Listen for changes to the hash in the URL
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []); // Empty dependency array to run only once on component mount

  
  
  return (
    <div>

<div  className="landing-page">

<span id="book-now"></span>

<div  className="landing-page-navbar">
<LandingNavTop/>
<LandingNavbar/>
</div>

<LandingHeader location={location} />


<NumberIncrement/>


{/* <LandingGroomingComponent location={location} /> */}

<GroomingPackageComponent />


<LandingHowWeWork location={location} />
<WhyFt />

<div className='landing-page-book-button'>
  <a href='#book-now' >Book Now</a>
</div>

<LandingOurLocations/>
<FaqsComponent/>
<LandingTestimonial/>

<LandingFooter/>


</div>
        
        
    </div>
  )
}

export default LandingPage